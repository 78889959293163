var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MERCH usersMessage"},[_c('div',{staticClass:"merch-card scorr-roll"},[(
        _vm.UserQualification.auditStatus != 10 &&
        _vm.UserQualification.auditStatus != 20
      )?_c('div',{staticClass:"hesd"},[_c('span',{class:_vm.UsersValue.userTimeLine == 1
            ? 'arrive'
            : _vm.UsersValue.userTimeLine > 1
            ? 'arrive'
            : 'await'},[_c('span',[_vm._v("1")]),_vm._v(" 资质审核")]),_c('span',{class:_vm.UsersValue.userTimeLine == 2
            ? 'arrive'
            : _vm.UsersValue.userTimeLine > 2
            ? 'arrive'
            : 'await'},[_c('span',[_vm._v("2")]),_vm._v("信息填写")]),_c('span',{class:_vm.UsersValue.userTimeLine == 3 ? 'arrive' : 'await'},[_c('span',[_vm._v("3")]),_vm._v("经营范围设置")])]):_vm._e(),_c('div',{style:(_vm.UserQualification.auditStatus == 20 ||
        _vm.UserQualification.auditStatus == 10
          ? 'height: 100%;'
          : '')},[(
          _vm.UserQualification.auditStatus == 20 ||
          _vm.UserQualification.auditStatus == 10
        )?_c('div',[_c('NotPassGood',{attrs:{"log_info":_vm.UserQualification}})],1):_vm._e(),(
          _vm.UsersValue.userTimeLine == 1 ||
          _vm.UsersValue.userTimeLine == 5 ||
          _vm.UsersValue.userTimeLine == 4
        )?_c('div',[_c('Message',{staticStyle:{"margin-top":"-30px"}})],1):_vm._e(),(
          _vm.UsersValue.userTimeLine == 2 ||
          _vm.UsersValue.userTimeLine == 5 ||
          _vm.UserQualification.auditStatus == 10 ||
          _vm.UserQualification.auditStatus == 20
        )?_c('div',[_c('AffirmMessage')],1):_vm._e(),(_vm.UsersValue.userTimeLine == 3)?_c('div',[_c('Manage')],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="MERCH usersMessage">
    <div class="merch-card scorr-roll">
      <div
        class="hesd"
        v-if="
          UserQualification.auditStatus != 10 &&
          UserQualification.auditStatus != 20
        "
      >
        <span
          class=""
          :class="
            UsersValue.userTimeLine == 1
              ? 'arrive'
              : UsersValue.userTimeLine > 1
              ? 'arrive'
              : 'await'
          "
        >
          <span>1</span> 资质审核</span
        >
        <span
          class=""
          :class="
            UsersValue.userTimeLine == 2
              ? 'arrive'
              : UsersValue.userTimeLine > 2
              ? 'arrive'
              : 'await'
          "
          ><span>2</span>信息填写</span
        >
        <span
          class=""
          :class="UsersValue.userTimeLine == 3 ? 'arrive' : 'await'"
          ><span>3</span>经营范围设置</span
        >
      </div>
      <div
        class=""
        :style="
          UserQualification.auditStatus == 20 ||
          UserQualification.auditStatus == 10
            ? 'height: 100%;'
            : ''
        "
      >
        <!-- tag标签 -->
        <div
          v-if="
            UserQualification.auditStatus == 20 ||
            UserQualification.auditStatus == 10
          "
        >
          <NotPassGood :log_info="UserQualification" />
        </div>
        <!-- <div class="merch-tag" v-else></div> -->
        <div
          v-if="
            UsersValue.userTimeLine == 1 ||
            UsersValue.userTimeLine == 5 ||
            UsersValue.userTimeLine == 4
          "
        >
          <Message style="margin-top: -30px" />
        </div>
        <div
          v-if="
            UsersValue.userTimeLine == 2 ||
            UsersValue.userTimeLine == 5 ||
            UserQualification.auditStatus == 10 ||
            UserQualification.auditStatus == 20
          "
        >
          <AffirmMessage />
        </div>
        <div v-if="UsersValue.userTimeLine == 3">
          <Manage />
        </div>
        <!-- <div
          class="btn-box"
          v-if="
            UserQualification.auditStatus == 20 ||
            UserQualification.auditStatus == 10
          "
        >
          <div class="btn-1 btn-2" @click="returnList">返回</div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Audit"); //vuex公共库
import Message from "./AuditMessage/message.vue";
import Manage from "./AuditMessage/manage.vue"; //经营
import AffirmMessage from "./AuditMessage/affirmMessage.vue"; //单位信息
import NotPassGood from "./AuditMessage/notPass.vue"; //产品资质
export default {
  data() {
    return {
      activeName: 0,
    };
  },
  components: {
    Message,
    Manage,
    AffirmMessage,
    NotPassGood,
  },
  computed: {
    ...mapState(["UsersValue", "UserQualification"]),
  },
  created() {
    if (this.$route.query.id) {
      this.postmemberAuditstatusLine({ serialNumber: this.$route.query.id });
    } else {
      this.$router.push({ path: "/contentHome" });
    }
  },
  methods: {
    ...mapActions(["postmemberAuditstatusLine"]),
    // 返回审核列表
    returnList() {
      this.$router.push({ path: "/userAudit" });
    },
  },
};
</script>
<style lang="scss" scoped>
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    height: 100%;
    min-height: 600px;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 100px;
      background: red;
    }
    .richText {
      margin-top: 30px;
      max-width: 800px;
      max-height: 85%;
      min-height: 50vh;
    }
    .compile-BTN {
      margin-top: 30px;
      width: 100%;
      display: flex;
      .cancel-btn {
        width: 100px;
        height: 40px;
        line-height: 40px;
        background: #f5f6f7;
        color: #c6c7c9;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
      }
      .save-btn {
        margin-left: 20px;
        background: #3d70ff;
        color: #fff;
      }
    }
  }
  .scrool-1 {
    width: 100%;
    height: 60vh;
  }
}
.btn-box {
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  .btn-1 {
    line-height: 5px;
    padding: 20px;
    background: #00bfbf;
    color: #fff;
    border-radius: 5px;
    margin-right: 20px;
  }
  .btn-2 {
    color: black;
    background: #d7d7d7;
  }
}
.arrive {
  color: #00bfbf;
  margin: 0 80px;
  span {
    border: 1px solid #00bfbf !important;
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    border-radius: 20px;
    background-color: #00bfbf;
    color: #fff;
    margin-right: 5px;
  }
}
.await {
  color: #999999;
  margin: 0 80px;
  span {
    border: 1px solid #999999 !important;
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    border-radius: 20px;
    // background-color: #00bfbf;
    color: #999999;
    margin-right: 5px;
  }
}
</style>

<template>
  <div>
    <div class="message-box">
      <!-- 文本信息 -->
      <div>
        <div class="firmBasics">企业基础信息</div>
        <div>
          <el-descriptions :column="3">
            <el-descriptions-item label="单位简称">
              {{ userBusic.shopName || "无" }}
            </el-descriptions-item>
            <el-descriptions-item label="单位名称">
              {{ userBusic.enterprise || "无" }}
            </el-descriptions-item>
            <el-descriptions-item label="法   人">
              {{ userBusic.legalEntity || "无" }}
            </el-descriptions-item>
            <el-descriptions-item label="注册手机号">
              {{ userBusic.mobile || "无" }}
            </el-descriptions-item>
            <el-descriptions-item label="企业注册地">
              {{ userBusic.address || "无" }}
            </el-descriptions-item>
            <el-descriptions-item label="详细地址">
              {{ userBusic.addressDetail || "无" }}
            </el-descriptions-item>
            <el-descriptions-item label="提交者">
              <div v-if="userBusic.salesPerson || userBusic.businessName || userBusic.groupName"><span style="margin-right: 10px;">【{{ userBusic.salesPerson }}】</span><span>【{{ userBusic.businessName }}>{{ userBusic.groupName }}】</span></div>
              <div v-else >无</div>
            </el-descriptions-item>
            <el-descriptions-item label="会员类型 ">
              <el-tag size="small"> {{ userBusic.typeString || "无" }}</el-tag>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <template>
        <!-- 资质信息-title -->
        <div class="qualification-title">
          <div class="qualification-title1">证件资料</div>
          <div
            @click="todown"
            class="qualification-title2"
            v-if="allJurisdiction.userAudit154"
          >
            <i class="el-icon-download"></i>下载以下所有证件
          </div>
        </div>
        <!-- 资质信息 -->
        <div class="Qualification">
          <!-- 资质申请信息 -->
          <div class="applyType-box">
            <div class="applyType">
              <div
                class="box-message"
                v-if="UserQualification.qualify.length > 0"
              >
                <div
                  class="qua-message-box"
                  v-for="item in amendUserQualification.qualify"
                  :key="item.id"
                >
                  <div class="qua-message-title">
                    <el-form ref="form" label-width="100px">
                      <el-form-item
                        :label="item.itemName"
                        :title="item.itemName"
                      >
                        <div class="sch-1 sch-2">
                          <div
                            class="sch-title"
                            v-if="
                              amendUserQualification.auditStatus != 20 &&
                              amendUserQualification.auditStatus != 10
                            "
                          >
                            效期时间:
                          </div>
                          <!-- 上传 -->
                          <el-date-picker
                            editable
                            format="yyyy-MM-dd"
                            v-if="
                              amendUserQualification.auditStatus != 20 &&
                              amendUserQualification.auditStatus != 10
                            "
                            v-model="item.effectiveEndTime"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="选择日期"
                            :disabled="
                              (item.effectiveEndTime != '9999-12-31' &&
                              amendUserQualification.auditStatus != 20 &&
                              amendUserQualification.auditStatus != 10
                                ? false
                                : true) ||
                              item.effectiveEndTime == `9999-12-31 00:00:00` ||
                              !item.picture
                            "
                            :picker-options="endDateOpt"
                          >
                          </el-date-picker>
                          <!-- 回显 -->
                          <div v-else>
                            有效期至：<span v-if="
                                item.effectiveEndTime == '9999-12-31 00:00:00'
                              ">长期有效</span
                            ><span v-else>{{
                              item.effectiveEndTime || "暂无"
                            }}</span>
                          </div>
                          <div
                            v-if="
                              amendUserQualification.auditStatus != 20 &&
                              amendUserQualification.auditStatus != 10
                            "
                          >
                            <div class="checkbox-must">
                              <el-checkbox
                                v-model="item.flang"
                                @change="
                                  item.flang
                                    ? (item.effectiveEndTime = `9999-12-31 00:00:00`)
                                    : (item.effectiveEndTime = '')
                                "
                                :disabled="!item.picture"
                              >
                                长期
                              </el-checkbox>
                            </div>
                          </div>
                          <div class="hinttxet" v-if="item.hint">
                            有效期不能为空
                          </div>
                        </div>
                      </el-form-item>
                    </el-form>
                  </div>
                  <div class="Qualification-img">
                    <el-form ref="form" label-width="100px">
                      <el-form-item>
                        <div class="zw-vox-img">
                          <div
                            class="img-box"
                            v-for="(v, i) in item.picture"
                            :key="i"
                          >
                            <div class="demo-image__preview upImg">
                              <el-image
                                :src="v"
                                :preview-src-list="item.picture"
                              >
                              </el-image>
                            </div>
                            <div class="describe">
                              {{
                                item.picture.length == 0 ? `未上传该资质` : ""
                              }}
                            </div>
                          </div>
                        </div>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
              </div>
              <div class="box-message-1" v-else>暂未上传相关资质</div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <template
      v-if="
        UserQualification.auditStatus != 20 &&
        UserQualification.auditStatus != 10
      "
    >
      <div class="btn-box">
        <div class="btn-2" style="line-height: 0px;" @click="$router.push({ path: '/userAudit' })">
          返回
        </div>
        <div class="btn-3" style="line-height: 0px;" @click="torefuse">申请驳回</div>
        <div class="btn-1" style="line-height: 0px;" @click="toNext1">通过并进入下一步</div>
        <div class="btn-1" style="line-height: 0px;" @click="toCustomerQualifs">首营系统审核</div>
        <div class="btn-1" style="line-height: 0px;" @click="refreshAudit">刷新进度</div>
      </div>
    </template>
    <!-- 弹框 -->
    <div>
      <el-dialog title="不通过原因" :visible.sync="isRefuse" width="600px">
        <div>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="RefuseValue.remark"
            maxlength="100"
            show-word-limit
          >
          </el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isRefuse = false">取 消</el-button>
          <el-button type="primary" @click="notarizeRefuse">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapMutations } = createNamespacedHelpers("Audit"); //vuex公共库
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
export default {
  data() {
    return {
      setisfinish:null,
      searchData: {},
      userBusic: {}, //会员基本信息
      isRefuse: false,
      reviewStatus:false,
      auditNumber:'',
      amendUserQualification: {},
      RefuseValue: {
        remark: "",
      }, //拒绝资质的理由
      endDateOpt: {
        disabledDate: (time) => {
          // 日期选择限制
          let oneDay = 60 * 60 * 24 * 1000;
          return time.getTime() < Date.now() - oneDay;
        },
        // selectableRange 用来限制时分秒的选择，这里要求只能选择当前时间之后到0点的时间点
        selectableRange: `${Date().split(" ")[4]} - 23:59:59`,
      },
    };
  },
  computed: {
    ...mapState(["UserQualification"]),
    ...commonIndex.mapState(["allJurisdiction"]),
  },
  created() {
    this.GetMessage();
  },

  methods: {
    ...mapActions([
      "getmemberAudituserDetail",
      "getmemberAudituserqualify",
      "postmemberAuditaudit",
      "postmemberAuditstatusLine",
      "postmemberAuditsavesLine",
      "getdownloadReview",
      "getQualifyEffective",
      "refreshAuditStatus"
    ]),
    // ...mapMutations(["popupHint"]),
    toCustomerQualifs(){
      var proUrl = "http://sysh.yaojuli.com"; //正式
      var csurl = "http://192.168.40.39:8009"; //测试
      var baseURL =
        window.location.origin.indexOf("prept.yaojuli.com") != -1
          ? proUrl
          : window.location.origin.indexOf("pt.yaojuli.com") != -1
          ? proUrl
          : window.location.origin.indexOf("192.168.40.39:9002") != -1
          ? csurl
          : csurl;
      //客户资质
      let inputkey = "serialNumber";
      let torouteName = "qualificationaudit";
      if(!this.auditNumber){
        this.auditNumber = ''
      }
      window.open(
        `${baseURL}/#/login?ly=yjl&inputkey=${inputkey}&inputvalue=${this.auditNumber}&torouteName=${torouteName}`
      );
    },
    async getAuditStatus(){
      let res = await this.getQualifyEffective({userQualifyId:this.$route.query.id})
      if(res.code*1 == 200){
        this.auditNumber = res.data.auditNumber
        if(res.data.reviewStatus){
          this.toNext()
        }
      }
    },
    async refreshAudit(){
      let res = await this.refreshAuditStatus({userQualifyId:this.$route.query.id})
      if(res.code*1 == 200){
          location.reload();
      }
    },
    //  下载
    async todown() {
      if (this.isfinish) {
					return
				}
			this.isfinish = true
      let data = await this.getdownloadReview({
        user_id: this.$route.query.user_id,
        serial_number: this.$route.query.id,
      });
      if (data.code == 200) {
        window.open(data.data, "");
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
      clearTimeout(this.setisfinish);
      this.setisfinish = setTimeout(() => {
        this.isfinish = false
      }, 1000)
    },
    // 调整数据结构
    cgData() {
      let qualify = [];
      this.amendUserQualification.qualify.forEach((v, i) => {
        if (v.detail) {
          this.$set(v, "picture", []);
          v.detail.forEach((v1) => {
            if (v1.itemType == 3) {
              v.picture.push(v1.imgUrl);
            }
          });
          qualify.push(v);
        }
      });
      this.amendUserQualification.qualify = [...qualify];
    },
    //更新时间线
    async updateTime(time) {
      let data = await this.postmemberAuditsavesLine({
        serialNumber: this.$route.query.id,
        userTimeLine: time,
      });
      if (data.code == 200) {
        this.postmemberAuditstatusLine({ serialNumber: this.$route.query.id });
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "error",
        });
      }
    },
    // 确认拒绝
    async notarizeRefuse() {
      this.RefuseValue.serialNumber = this.$route.query.id;
      this.RefuseValue.auditStatus = 10;
      this.RefuseValue.id = sessionStorage.getItem("admin_id");
      // this.RefuseValue.reviewId = this.$route.query.reviewId;
      if (this.RefuseValue.remark != "") {
        let data = await this.postmemberAuditaudit(this.RefuseValue);
        if (data.code == "000000") {
          this.updateTime(5);
          this.$router.push({ path: "/userAudit" });
          this.isRefuse = false;
        } else {
          if (data.code == 90000) {
            this.$alert(
              "您审核的当前会员已重新提交更新资质申请，点击确定返回列表，请以新的信息为准进行审核。",
              "会员资质更新提醒",
              {
                confirmButtonText: "确定",
                callback: (action) => {
                  this.$router.push({ path: "/userAudit" });
                },
              }
            );
          }
        }
      } else {
        this.$message({
          message: "请填写拒绝理由",
          type: "warning",
        });
      }
    },
    // 拒绝
    torefuse() {
      this.isRefuse = true;
    },
    // 获取会员资质详情
    async GetMessage() {
      let basicsMessageData = await this.getmemberAudituserDetail({
        userId: this.$route.query.user_id,
        userQualifyId:this.$route.query.id
      });
      let data = await this.getmemberAudituserqualify({
        userQualifyId: this.$route.query.id,
      });
      this.userBusic = basicsMessageData.content;
      this.amendUserQualification = data.content;
      this.cgData();
      if(this.amendUserQualification.auditStatus != 20 && this.amendUserQualification.auditStatus != 10){
        this.getAuditStatus()
      }
    },
    // 通过审核下一步 
    async toNext1() {
      let UpUserData = {
        effective: [],
      };
      this.amendUserQualification.qualify.forEach((item) => {
        if (item.picture && item.effectiveEndTime) {
          UpUserData.effective.push({
            id: item.id,
            effectiveEndTime: item.effectiveEndTime,
          });
          item.hint = false;
        } else if (item.picture && !item.effectiveEndTime && item.requiredType*1 == 1) {
          item.hint = true;
          // if(item.requiredType*1 == 1){
          //   item.hint = true;
          // }else{
          //   item.hint = false;
          // }
          // this.popupHint();
        }
      });
      let num = this.amendUserQualification.qualify.filter((v) => {
        return v.hint == true;
      });
      if (num.length > 0) {
        return false;
      }
      UpUserData.serialNumber = this.$route.query.id;
      UpUserData.auditStatus = 20;
      UpUserData.id = sessionStorage.getItem("admin_id");
      let data = await this.postmemberAuditaudit(UpUserData);
      if (data.code == "000000") {
        this.updateTime(2);
      } else {
        // this.updateTime(2);
      }
    },
    // 首营刷新进度 调用审核通过
    async toNext() {
      let UpUserData = {
        effective: [],
      };
      this.amendUserQualification.qualify.forEach((item) => {
        if (item.picture && item.effectiveEndTime) {
          UpUserData.effective.push({
            id: item.id,
            effectiveEndTime: item.effectiveEndTime,
          });
        } 
      });
      UpUserData.serialNumber = this.$route.query.id;
      UpUserData.auditStatus = 20;
      UpUserData.id = sessionStorage.getItem("admin_id");
      let data = await this.postmemberAuditaudit(UpUserData);
      if (data.code == "000000") {
        this.updateTime(2);
      } else {
        // this.updateTime(2);
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.checkbox-must {
  margin-left: 4px;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  padding: 0px 0px !important;
  width: 20% !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
/* .el-date-picker {
  width: 300px !important;
} */
.el-form-item__label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.el-textarea__inner {
  height: 200px;
}
</style>
<style lang="scss" scoped>
.message-box {
  width: 100%;
  padding-top: 20px;
  // padding-left: 20px;
  .firmBasics {
    font-size: 15px;
    font-weight: 700;
    height: 30px;
    border-bottom: 1px dashed #ddd;
    display: flex;
    align-items: flex-end;
    color: #000;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .firmBasics::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
  }
  .qualification-title {
    font-size: 15px;
    font-weight: 700;
    width: 100%;
    height: 50px;
    color: #000;
    border-bottom: 1px dashed #ddd;
    display: flex;
    align-items: flex-end;
    // justify-content: space-between;
    padding-bottom: 10px;
  }
  .qualification-title1::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
  .qualification-title2 {
    font-size: 12px;
    color: #06b7ae;
    font-weight: normal;
    margin-left: 10px;
    cursor: pointer;
  }
  .qualification-message {
    margin-top: 10px;
    width: 100%;
    .title {
      width: 150px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 4 px;
      background: #00bfbf;
      color: #fff;
      font-size: 15px;
      font-weight: 700;
    }
  }
  .qualification-message-img-box {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .makeOut {
    padding-left: 5%;
    padding-right: 5%;
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .makeout-box {
      margin-top: 10px;
      width: 40%;
      height: 40px;
      display: flex;
      .sch-1 {
        width: 80%;
        min-width: 200px;
        display: flex;
        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
    }
  }
}
.Qualification {
  margin-top: 20px;
  width: 100%;
  .quaName {
    font-size: 14px;
    font-weight: bold;
    color: #383b46;
  }
  .quaType {
    font-weight: bold;
    color: #3d70ff;
    font-size: 14px;
  }
  .applyType-box {
    padding-top: 10px;
    width: 100%;
    .applyType {
      width: 100%;
      min-width: 800px;
      // padding: 0px 100px;
      .applyType-name {
        width: 150px;
        height: 40px;
        background: #3d70ff;
        text-align: center;
        line-height: 40px;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        font-size: 14px;
        font-weight: bold;
        color: #fff;
      }
      .box-message {
        display: flex;
        flex-wrap: wrap;
      }
      .box-message-1 {
        width: 100%;
        height: 50px;
        line-height: 50px;
      }
      .qua-message-box {
        margin-top: 20px;
        width: 50%;
        .qua-message-title {
          width: 50%;
          height: 40px;
          .sch-1 {
            width: 100%;
            min-width: 700px;
            display: flex;
            .sch-title {
              width: 20%;
              min-width: 80px;
              height: 40px;
              font-size: 13px;
              background-color: #f5f7fa;
              color: #909399;
              border: 1px solid #dcdfe6;
              border-right: none;
              border-radius: 4px 0px 0px 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0px 0px 0px 5px !important;
            }
          }
        }
        .Qualification-img {
          width: 50%;
        }
        .zw-vox-img {
          width: 300px;
          margin-right: 10px;
          display: flex;
          flex-wrap: wrap;
        }
        .img-box {
          // width: 100px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin-right: 10px;
          .upImg {
            width: 140px;
            height: 140px;
            margin-top: 10px;
            .el-image {
              width: 140px;
              height: 140px;
            }
          }
          .describe {
            line-height: 20px;
            padding: 0px;
            width: 100%;
            height: 20px;
            font-size: 12px;
            text-align: center;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.operating-btn {
  width: 100%;
  height: 50px;
  display: flex;

  .btn-1 {
    width: 80px;
    height: 40px;
    background: #f5f6f7;
    color: #bbbdbf;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    margin-left: 20px;
  }
  .btn-2 {
    background: #3d70ff;
    color: #fff;
  }
}
.hint {
  border: 1px solid #f00;
}
.hinttxet {
  font-size: 12px;
  margin-left: 5px;
  color: #f00;
}
::v-deep .el-descriptions-item__label {
  width: 90px !important;
}
</style>

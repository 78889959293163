<template>
  <div class="merch-box">
    <!-- <div class="title">审核结果</div>
    <div class="center-box">
      <div class="passImg" v-if="log_info.auditStatus == 10">未通过</div>
      <div v-if="log_info.auditStatus == 20">通过</div>
      <div class="pass-text" v-if="log_info.auditStatus == 2">
        <span v-if="log_info.remark != null">
          不通过原因：{{ log_info.remark }}
          <span v-if="!textIf" class="text-item" @click="Text">
            {{ textShow == false ? " 展开全部" : "收起" }}
          </span>
        </span>
        <span v-else>未填写拒绝原因</span>
      </div>
      <div class="verifier-per">
        <div>审核人：{{ log_info.operatorname || "/" }}</div>
        <div>
          {{ log_info.updateTime || "/" }}
        </div>
      </div>
    </div> -->
    <div class="title">审核结果</div>
    <div class="center-box">
      <div class="passImg" v-if="log_info.auditStatus == 10">
        <img src="@/assets/img/audit/refuse.png" alt="" />
        未通过
      </div>
      <div class="passImg" v-if="log_info.auditStatus == 20">
        <img src="@/assets/img/audit/pass.png" alt="" />
        已通过
      </div>

      <div class="verifier-per">
        <!-- class="exceed" -->
        <div style="height:20px">审核人：{{ log_info.operatorName || "/" }}</div>
        <!-- class="exceed" -->
        <div style="height:20px">{{ log_info.updateTime }}</div>
        <div class="pass-text" v-if="log_info.auditStatus == 10">
          <span v-if="log_info.remark">
            不通过原因：{{ log_info.remark }}
          </span>
          <span v-else>未填写拒绝原因</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    log_info: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      UserQualification: {
        qualify: {
          status: 1,
        },
      },
      textIf: true, // 展开收起隐藏
      textShow: false, // 展开收起切换
      textMax: 20, // 显示最多字数
      detailText: "", // 文字内容
    };
  },
  created() {},
  computed: {
    sliceStr() {
      if (this.detailText == "" || this.detailText == undefined) {
        return this.detailText; // 数据为空直接返回数据
      } else {
        if (
          this.detailText.length > this.textMax ||
          this.textMax == +Infinity
        ) {
          this.textIf = false;
          return this.detailText.length > this.textMax
            ? this.detailText.substring(0, this.textMax) + "..."
            : this.detailText.substring(0, this.textMax); //隐藏多余文字+"..."
        } else {
          this.textIf = true;
        }
      }
      return this.detailText;
    },
  },
  methods: {
    Text() {
      this.textShow = !this.textShow;
      this.textShow == false ? (this.textMax = 20) : (this.textMax = +Infinity);
    },
  },
};
</script>

<style lang="scss" scoped>
.merch-box {
  // padding: 10px;
  // padding-left: 20px;
  width: 100%;
  .title {
    width: 100%;
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: dashed 1px #ddd;
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
  .center-box {
    // padding-left: 20px;
    // padding-right: 20px;
    // margin-top: 10px;
    width: 100%;
    height: 100%;
    font-weight: bold;
    // border: 1px solid #797979;
    border-radius: 4px;
    display: flex;
    // justify-content: space-between;
    img {
      width: 60px;
      height: 60px;
      margin-bottom: 10px;
    }
    .passImg {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      margin-left: 35px;
      margin-bottom: 30px;
    }
    .pass-text {
      line-height: 20px;
      // padding-top: 90px;
      width: 80%;
      min-width: 500px;
      // height: 100%;
      // min-height: 100px;
      text-align: left;
      display: flex;
      align-items: flex-end;
      font-size: 13px;
      .text-item {
        color: #00bfbf;
        cursor: pointer;
        font-weight: 700;
      }
    }
    .verifier-per {
      height: 94px;
      display: flex;
      flex-wrap: wrap;
      // align-items: center;
      margin-top: 40px;
      margin-left: 62px;
      justify-content: space-between;
      font-weight: normal;
      font-size: 14px;
      div {
        width: 100%;
      }
      
    }
  }
}
</style>
